import { Injectable } from '@angular/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { Observable, from } from 'rxjs';
import { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class ModalService {
  
  private modal!: SwalComponent;

  private isDarkTheme!: boolean;

  public setModal(modal: SwalComponent):void{
    this.modal = modal;
  }

  public get configuration(): SweetAlertOptions {
    return this.modal.swalOptions;
  }

  public createConfirmationModal(title: string, content?: string): Observable<SweetAlertResult> {
    this.resetModal();
    
    const configuration: SweetAlertOptions = {
      title: title,
      html: content,
      showConfirmButton: true,
      showCancelButton: true,
      buttonsStyling: false,
      confirmButtonText: "Continue",
      cancelButtonText: "Close",
      customClass:{
        title: "text-left",
        confirmButton: "button w-4/12",
        cancelButton: "button w-4/12 button-outline dark:border-secondary dark:text-secondary",
        actions: "w-full justify-around flex-row-reverse"
      }
    }

    this.applyConfiguration(configuration);

    return from(this.modal.fire())
  }

  public createInformationModal(title: string, content?: string): Observable<SweetAlertResult>{
    this.resetModal();
    
    const configuration: SweetAlertOptions = {
      title: title,
      html: content,
      showConfirmButton: true,
      showCancelButton: false,
      buttonsStyling: false,
      confirmButtonText: "Continue",
      customClass:{
        title: "text-center",
        confirmButton: "button w-4/12",
        htmlContainer: 'font-main',
        actions: "w-full justify-around flex-row-reverse"
      }
    }

    this.applyConfiguration(configuration);

    return from(this.modal.fire())
  }

  public createErrorModal(content?: string): Observable<SweetAlertResult> {
    this.resetModal();
    
    let contenido: string;
    !content ? contenido = "There was an unexpected error. Please, try again later." : contenido = content;
    
    const configuration: SweetAlertOptions = {
      title: `<span class="dark:text-secondary">System Message</span>`,
      showConfirmButton: false,
      background: '#fff',
      html: `
      <div class="text-center my-2 text-base dark:text-gray-500">
        <p>${contenido}</p>
      </div>
      `,
    }

    this.applyConfiguration(configuration);

    return from(this.modal.fire())
  }

  public createLogOutModal(): Observable<SweetAlertResult> {

    this.resetModal();

    const configuration: SweetAlertOptions = {
      title: 'Continue ?',
      html: '<p class="text-left">Are you sure you want to log out?</p>',
      showConfirmButton: true,
      showCancelButton: true,
      buttonsStyling: false,
      confirmButtonText: 'Log Out',
      cancelButtonText: 'Back',
      customClass:{
        title: 'text-left',
        confirmButton: "button w-4/12",
        cancelButton: "button w-4/12 button-outline dark:border-secondary dark:text-secondary",
        actions: "w-full justify-around flex-row-reverse border-t pt-4",
      }
    }

    this.applyConfiguration(configuration);

    return from(this.modal.fire());
  }

  public createInputModal(title: string, content: string,  inputType: 'text' | 'email' | 'password', placeholder: string, errMsg?: string): Observable<SweetAlertResult<string>> {
    this.resetModal();

    const configuration: SweetAlertOptions = {
      title: title,
      html: content,
      input: inputType,
      inputPlaceholder: placeholder,
      showConfirmButton: true,
      showCancelButton: true,
      buttonsStyling: false,
      confirmButtonText: 'Continue',
      cancelButtonText: 'Close',
      customClass:{
        htmlContainer: 'font-main',
        input: 'input',
        confirmButton: "button w-4/12",
        cancelButton: "button w-4/12 button-outline dark:border-secondary dark:text-secondary",
        actions: "w-full justify-around flex-row-reverse"
      },
      inputValidator: (value) => {
        if(inputType === 'email' && value && !value.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)){
          return 'Invalid email. Please, try again.';
        }else if (!value) {
          return errMsg || 'Invalid input. Please, try again.';
        }else{
          return void 0;
        }
      }
      
    }

    this.applyConfiguration(configuration);

    return from(this.modal.fire());
  }

  public createOptionsModal(title: string, options: {[x:number]: string}): Observable<SweetAlertResult<number>> {
    this.resetModal();

    const configuration: SweetAlertOptions = {
      allowEscapeKey: false,
      allowOutsideClick: false,
      buttonsStyling: false,
      confirmButtonText: 'Continue',
      title: title,
      showConfirmButton: true,
      showCloseButton: false,
      showCancelButton: false,
      icon: 'info',
      input: 'radio',
      inputOptions: options,
      inputValidator: (value) => {
        if (!value) {
          return 'You need to select an option.';
        }
          return void 0;
      },
      customClass: {
        input: 'font-main flex flex-col justify-start items-start gap-y-5 text-xs',
        confirmButton: 'button border-0',
      },
    }

    this.applyConfiguration(configuration);
    return from(this.modal.fire());

  }

  private applyConfiguration(configuration: SweetAlertOptions){
    
    this.isDarkTheme ? configuration.background = "#111827" : configuration.background = "#fff"
  
    this.modal.swalOptions = configuration;

  }
  
  private resetModal(): void {
     
      // Reset modal properties
      this.modal.title          = '';
      this.modal.html           = '';
      this.modal.icon           = undefined;
      this.modal.input          = undefined;
      this.modal.inputOptions   = {};
      this.modal.inputValidator = () => {return};
      this.modal.swalOptions    = {};
      
      // Check dark mode
      this.checkDarkMode();
  }

  private checkDarkMode(): void {
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? this.isDarkTheme = true : this.isDarkTheme = false;
  }
}
